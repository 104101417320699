import filter from 'lodash/filter'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/uniq'
import React, { useState } from 'react'
import ReactSelect, { components } from 'react-select'
import styled from 'styled-components'
import CheckIcon from '../../atoms/graphics/small-check-icon'
import theme from '../../atoms/theme'
import { EventType } from './event-type'

interface Option {
  label: string
  value: string
  isSelected?: boolean
}

interface Props {
  data: Array<EventType>
  setData: (data: Array<EventType>) => void
  filteredData: Array<EventType>
}

interface LocationFilterProps {
  options: Array<string>
  defaultValue: string
  onChange: (option: Option) => void
}

const LocationFilterContainer = styled.div`
  border: solid 2px ${theme.colours.gray1};
  border-radius: 0.8rem;
  width: 26rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 16rem;
  }
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 2.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.4rem;
  }
`

const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 25rem;
  justify-content: center;
  align-items: center;

  > div {
    width: 18rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    justify-content: flex-end;
    div {
      padding: 0;
    }
    > div {
      width: 13.3rem;
      margin-left: 0.5rem;
    }
  }
`

const customStyles = {
  container: (provided): object => ({
    ...provided,
    width: 'auto',
  }),
  control: (provided): object => ({
    ...provided,
    border: 'none',
  }),
  option: (provided, state): object => ({
    ...provided,
    backgroundColor: state.isFocused
      ? theme.colours.gray4
      : theme.colours.white,
    color: theme.colours.black,
    display: 'flex',
    justifyContent: 'space-between',
  }),
  menu: (provided): object => ({
    ...provided,
    marginTop: 0,
  }),
  menuList: (provided): object => ({
    ...provided,
    borderBottom: `0.4rem solid ${theme.colours.quaternary}`,
    padding: 0,
  }),
}

const { Option } = components
const CustomOption = (props: Option): JSX.Element => {
  return (
    <Option {...props}>
      {props.label}
      {props.isSelected && <CheckIcon />}
    </Option>
  )
}

const Filter = (props: LocationFilterProps): JSX.Element => {
  const selectOptions = props.options.map((option): object => ({
    label: option,
    value: option,
  }))

  const defaultValue = props.defaultValue
    ? {
        label: props.defaultValue,
        value: props.defaultValue,
      }
    : null

  const handleOnChange = (newValue): void => {
    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <ReactSelect
      defaultValue={defaultValue}
      options={selectOptions}
      styles={customStyles}
      onChange={handleOnChange}
      isSearchable={false}
      components={{
        IndicatorSeparator: (): void => null,
        Option: CustomOption,
      }}
    />
  )
}

const EventFilters = (props: Props): JSX.Element => {
  const sortOptions = ['Date', 'Title ascending', 'Title descending']
  const [sortBy, setSort] = useState('Date')
  const filterOptions = uniq(map(props.data, 'locationCity'))
  filterOptions.unshift('All locations')

  const sortData = (sort, data = props.filteredData): void => {
    let sortedData: Array<EventType> // Specify the type as Array<EventType>
    switch (sort) {
      case 'Date':
        sortedData = orderBy(data, ['startDate'], ['asc'])
        break
      case 'Title ascending':
        sortedData = orderBy(data, ['title'], ['asc'])
        break
      case 'Title descending':
        sortedData = orderBy(data, ['title'], ['desc'])
        break
    }
    props.setData(sortedData)
  }

  const handleSort = (sort): void => {
    setSort(sort.value)
    sortData(sort.value)
  }

  const handleFilterLocations = (location): void => {
    if (location.value === 'All locations') {
      sortData(sortBy, props.data)
    } else {
      const data = filter(props.data, ['locationCity', location.value])
      sortData(sortBy, data)
    }
  }

  return (
    <FilterContainer>
      <LocationFilterContainer>
        <Filter
          options={filterOptions}
          defaultValue={filterOptions[0]}
          onChange={handleFilterLocations}
        />
      </LocationFilterContainer>
      <SortContainer>
        <span>Sort by:</span>
        <div>
          <Filter
            options={sortOptions}
            defaultValue={sortOptions[0]}
            onChange={handleSort}
          />
        </div>
      </SortContainer>
    </FilterContainer>
  )
}
export default EventFilters
