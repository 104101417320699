import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const CheckIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Check</title>
      <path
        fill={props.colour ? props.colour : theme.colours.black}
        fillRule='evenodd'
        d='M18.826 8.7l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.3 3.3 7.3-7.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z'
      />
    </svg>
  )
}

export default CheckIcon
