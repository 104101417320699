import * as React from 'react'
import styled from 'styled-components'
import theme from '../../atoms/theme'

const pbtIcon = require('../../../assets/pbt-icon-gray.jpg') as string

interface Props {
  isWorkshop: boolean
}

const EmptyContainer = styled.div`
  height: 55rem;
  background-image: url(${pbtIcon});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: 30rem;
  }
`

const EmptyHeader = styled.h2`
  color: ${theme.colours.secondary};
  text-align: center;
  width: 70%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2.1rem;
  }
`

const EmptyEvents = (props: Props): JSX.Element => {
  return (
    <EmptyContainer>
      <EmptyHeader>
        {props.isWorkshop
          ? 'We currently don’t have any upcoming workshops scheduled, check back soon for more updates!'
          : 'We currently don’t have any upcoming events scheduled, check back soon for more updates!'}
      </EmptyHeader>
    </EmptyContainer>
  )
}

export default EmptyEvents
