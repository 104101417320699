import { Link } from 'gatsby'
import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import ArrowRight from '../../atoms/graphics/arrow-right'
import CalendarIcon from '../../atoms/graphics/calendar-icon'
import MapPinIcon from '../../atoms/graphics/map-pin-icon'
import theme from '../../atoms/theme'
import EventCapacity from './event-capacity'
import { EventType } from './event-type'

interface Props {
  data: EventType
}

const EventContainer = styled.div`
  border: solid 1px ${theme.colours.gray1};
  border-bottom: none;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  &:last-child {
    border-bottom: solid 1px ${theme.colours.gray1};
  }
`

const DetailContainer = styled.div`
  padding: 4.5rem 3rem;
  flex-grow: 2;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 2.5rem 1.5rem;
  }
`

const StyledImage = styled.img`
  height: 25rem;
  width: 36rem;
  padding: 2.5rem 0 2.5rem 2.5rem;
  object-fit: contain;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const EventTitle = styled.h3`
  color: ${theme.colours.secondary};
  margin-bottom: 0;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.9rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const DetailsLink = styled(Link)`
  color: ${theme.colours.quaternary};
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${theme.fonts.primaryBold};
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`
const MobileDetailsLink = styled(DetailsLink)`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: flex;
  }
`

const StyledEventType = styled.div`
  color: ${theme.colours.gray1};
  font-size: 1.6rem;
`

const EventDetail = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primaryBold};
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  span {
    padding: 0 1rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.4rem;
    padding-bottom: 0.5rem;
    align-items: start;
    span {
      padding: 0 0.5rem;
    }
  }
`

const EventTime = styled(EventDetail)`
  span:last-child {
    border-left: 1px solid ${theme.colours.gray3};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    span:last-child {
      border-left: none;
    }
    div {
      display: flex;
      flex-wrap: wrap;
    }
  }
`

const CapacityContainer = styled.div`
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
  }
`

const EventBlock = (props: Props): JSX.Element => {
  const startDate = new Date(props.data.startDate)
  const endDate = new Date(props.data.endDate)

  const getDate = (): string => {
    const weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    return `${weekday[startDate.getDay()]}, ${startDate.getDate()} ${moment(startDate).format('MMMM')}`
  }

  const getTime = (): string => {
    const startTime = moment(startDate).format('h:mm A')
    const endTime = moment(endDate).format('h:mm A')

    return `${startTime} - ${endTime}`
  }

  const getLink = (): string => {
    const id = props.data.id
    return props.data.eventType === 'Workshop Event'
      ? `/details/workshops/${id}/`
      : `/details/events/${id}/`
  }

  return (
    <EventContainer>
      {props.data.imageUrl && (
        <StyledImage src={props.data.imageUrl} alt={props.data.title} />
      )}
      <DetailContainer>
        <Row>
          <EventTitle>{props.data.title}</EventTitle>
          <DetailsLink to={getLink()}>
            Details <ArrowRight colour={theme.colours.quaternary} />
          </DetailsLink>
        </Row>
        {props.data.detailedEventType && (
          <Row>
            <StyledEventType>{props.data.detailedEventType}</StyledEventType>
          </Row>
        )}

        <p>{props.data.shortDescription}</p>
        <Row>
          <div>
            {props.data.startDate && props.data.endDate && (
              <EventTime>
                <CalendarIcon />{' '}
                <div>
                  <span>{getDate()}</span> <span>{getTime()}</span>
                </div>
              </EventTime>
            )}
            {props.data.locationCity && (
              <EventDetail>
                <MapPinIcon /> <span>{props.data.locationCity}</span>
              </EventDetail>
            )}
          </div>
          <CapacityContainer>
            {props.data.seatingStatus && (
              <EventCapacity seatingStatus={props.data.seatingStatus} />
            )}
            <MobileDetailsLink to={getLink()}>
              Details <ArrowRight colour={theme.colours.quaternary} />
            </MobileDetailsLink>
          </CapacityContainer>
        </Row>
      </DetailContainer>
    </EventContainer>
  )
}

export default EventBlock
